<template lang="pug">
.CancelTurn
  .banderaSvgMobile
  Modal(:header="true" :goBack="goBack")
    template(#body)
      .d-flex.justify-content-center.align-items-start
        img(
          src="@/assets/mobileFSFB/info_data.svg"
          alt="confirm_data"
        ).image-confirm_data.pl-3
        h2.text-confirm_data.ml-3: strong ¿Estás seguro de cancelar?
      .d-flex.justify-content-between.pt-3.pb-4
        button(
          @click="goBack"
        ).buttonBack.px-4.py-2 Regresar
        button(
          @click="onContinue"
        ).buttonConfirm.px-4.py-2
          span(v-if="!prelaod") Confirmar
          span(v-else)
            MiniSpinner
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CancelTurn",

  data: () => ({
    prelaod: false
  }),

  components: {
    Modal: () => import("./components/Modal.vue"),
    MiniSpinner: () => import("./components/MiniSpinner.vue")
  },

  watch: {
    status(val) {
      if (val == "CANCELED_BY_USER") {
        this.goToView({ view: "Home" });
      }
    }
  },

  computed: {
    ...mapState({
      turn: state => state.turns.info,
      embebedData: state => state.mobileFSFB.embebedData
    }),
    status() {
      return this.turn?.status;
    }
  },

  methods: {
    ...mapActions({
      cancelTurnAction: "turns/cancelTurnAction",
      goToView: "mobileFSFB/goToView",
      goBack: "mobileFSFB/goBack"
    }),

    async onContinue() {
      this.prelaod = true;
      try {
        // throw new Error("dsfdsfsdfsdf");
        await this.callApi();
      } catch (error) {
        console.error(error);
        this.goToView({
          view: "Error",
          actionError: this.callApi,
          fromError: "CancelTurn"
        });
      }
      this.prelaod = false;
    },

    async callApi() {
      await this.cancelTurnAction();
      localStorage.clear();
      // Get a reference to the last interval + 1
      const interval_id = window.setInterval(function() {},
      Number.MAX_SAFE_INTEGER);

      // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }
      // this.goToView({ view: "Home" });
      this.goToView({ view: "Canceled" });
      if (this.embebedData)
        this.$router.push({
          path: "/mobile_fsfb",
          query: { embebedData: this.embebedData }
        });
    }
  }
};
</script>

<style lang="scss">
.CancelTurn {
  height: 100%;
}

.blueText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #69cdfe;
}

.logoCancelTurn {
  top: 0;
  left: auto;
  transform: none;
  margin-top: 56px;
  margin-bottom: 20%;
  position: relative;
}

.text-confirm_data {
  width: 177px;
}

.boxDarker {
  background: #202d78;
  border-radius: 20px;
}
</style>
